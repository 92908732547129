/* eslint-enable */
/* eslint-disable */

// Book a call button functionality

const callButtons = document.querySelectorAll('.book-a-call-btn')
const formPopup = document.querySelector('#from-popup')
const formPopupCloseBtn = document.querySelector('.form-popup__close')

formPopup?.addEventListener('click', (e)=> {
    const container = formPopup.querySelector('.form-popup__container');
    if (e.target == container) {
        gsap.to(formPopup, {
            scale: 0,
            opacity: 0,
            pointerEvents: 'none',
            duration: 0.25,
            ease: "power1.inOut"
        })
    
        gsap.to('body', {
            overflow: 'auto'
        })    
    }
})

callButtons.forEach((btn => {
    btn.addEventListener('click', () => {
        gsap.to(formPopup, {
            scale: 1,
            opacity: 1,
            pointerEvents: 'all',
            duration: 0.25,
            ease: "power1.inOut"
        })

        gsap.to('body', {
            overflow: 'hidden'
        })
    })
}))

formPopupCloseBtn?.addEventListener('click', () => {
    gsap.to(formPopup, {
        scale: 0,
        opacity: 0,
        pointerEvents: 'none',
        duration: 0.25,
        ease: "power1.inOut"
    })

    gsap.to('body', {
        overflow: 'auto'
    })
})

const input = document.getElementById('form-popup__date--date');
const today = new Date();
const formattedDate = today.toISOString().split('T')[0];
input?.setAttribute('min', formattedDate);

const inputTime = document.getElementById('form-popup__time--time');
const now = new Date();
const hours = now.getHours().toString().padStart(2, '0');
const minutes = now.getMinutes().toString().padStart(2, '0');
const formattedTime = `${hours}:${minutes}`;
inputTime?.setAttribute('min', formattedTime);

const formPopupBtn = document.querySelector('.from-popup__button')

formPopupBtn?.addEventListener('click', () => { })
